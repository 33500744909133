import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Bootstrap from "Bootstrap";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from "utils/env";
import { getClientConfig } from "./config";

if (SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: true,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        traceFetch: false,
        traceXHR: true,
        tracingOrigins: [
          "localhost",
          "api.zuplo.com",
          "api-staging.zuplo.com",
          /zuplo\.dev/g,
        ],
      }),
    ],
    release: SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
}

const { pageTitle, faviconUrl } = getClientConfig();

if (pageTitle) {
  document.title = pageTitle;
}

const faviconLink = document.querySelector(
  "link[rel*='icon']"
) as HTMLLinkElement;
faviconLink.href = faviconUrl;
const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
const ProfiledApp = Sentry.withProfiler(Bootstrap);

root.render(<ProfiledApp />);
