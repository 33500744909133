import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import ConfigurationWrapper from "components/common/ConfigurationWrapper";
import { simpleErrorFallback } from "components/common/error-handling/SimpleErrorFallback";
import LoadingIcon from "components/layout/Loading";
import { FeatureFlagsProvider } from "context/FeatureFlagsContext";
import { ThemeContext } from "context/ThemeContext";
import { UserGeneratedPageContext } from "context/UserGeneratedPageContext";
import UserGeneratedPage from "pages/UserGeneratedPage";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { SWRConfig } from "swr";
import { applyTheme } from "themes/utils";

import {
  getUserGeneratedPages,
  UserGeneratedPageConfig,
} from "utils/userGeneratedContent";
import "./App.css";
import Layout from "./components/layout/Layout";
import LogoutPage from "./pages/LogoutPage";
import { LIGHT_THEME } from "./themes/light";

const APIDocPage = React.lazy(() => import("pages/APIDocPage"));
function App() {
  const [theme, setTheme] = useState(LIGHT_THEME);
  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const [userGeneratedPages, setUserGeneratedPages] = useState<
    UserGeneratedPageConfig[]
  >([]);

  useEffect(() => {
    const getPages = async () => {
      const pages = await getUserGeneratedPages();
      if (pages != null) {
        setUserGeneratedPages(pages);
      }
    };
    void getPages();
  }, []);

  const userGeneratedRoutes = userGeneratedPages.map(
    (content: { id: string; url: string }) => (
      <Route
        key={content.id}
        path={`/${content.id}`}
        element={<UserGeneratedPage content={content} />}
      />
    )
  );

  return (
    <ConfigurationWrapper>
      <FeatureFlagsProvider>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("location", "App");
          }}
          fallback={simpleErrorFallback}
          showDialog={false}
        >
          <SWRConfig
            value={{
              fetcher: (resource, init) =>
                fetch(resource, init).then((res) => res.json()),
            }}
          >
            <Layout>
              <Suspense
                fallback={
                  <LoadingIcon className="h-40 inline mx-auto w-full" />
                }
              >
                <ThemeContext.Provider value={[theme, setTheme]}>
                  <UserGeneratedPageContext.Provider value={userGeneratedPages}>
                    <SentryRoutes>
                      <Route path="/__logout" element={<LogoutPage />} />
                      {userGeneratedRoutes}
                      <Route
                        path="/*"
                        element={
                          <div className="App bg-primary-background">
                            <APIDocPage />
                          </div>
                        }
                      />
                      <Route
                        element={
                          <div className="App bg-primary-background">
                            <APIDocPage />
                          </div>
                        }
                      />
                    </SentryRoutes>
                  </UserGeneratedPageContext.Provider>
                </ThemeContext.Provider>
              </Suspense>
            </Layout>
          </SWRConfig>
        </ErrorBoundary>
      </FeatureFlagsProvider>
    </ConfigurationWrapper>
  );
}

export default App;
