import { ErrorBoundary } from "@sentry/react";
import App from "App";
import { simpleErrorFallback } from "components/common/error-handling/SimpleErrorFallback";
import React from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import { getClientConfig } from "./config";

const Bootstrap = () => {
  const {
    sitePathname,
    auth: { authority, clientId, audience },
  } = getClientConfig();
  const identityProviderOptions: AuthProviderProps = {
    authority,
    client_id: clientId,
    extraQueryParams: {
      audience,
    },
    redirect_uri: `${window.location.origin}${sitePathname}`,
    scope: "openid profile email",
  };
  return (
    <React.StrictMode>
      <BrowserRouter basename={sitePathname}>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("location", "index");
          }}
          fallback={simpleErrorFallback}
          showDialog={false}
        >
          <AuthProvider {...identityProviderOptions}>
            <App />
          </AuthProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default Bootstrap;
