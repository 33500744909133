import { MoonIcon, SunIcon } from "@heroicons/react/solid";
import Toggle from "components/common/Toggle";
import { ThemeContext } from "context/ThemeContext";
import { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { getClientConfig } from "../../../config";
import { DARK_THEME } from "../../../themes/dark";
import { LIGHT_THEME } from "../../../themes/light";
import LoginButton from "./LoginButton";
import UserIcon from "./UserIcon";

const Header = () => {
  const { auth } = getClientConfig();
  const { isAuthenticated, isLoading, error } = useAuth();
  const [theme, setTheme] = useContext(ThemeContext);
  const isAuthDataMissing = !(auth.authority && auth.clientId && auth.audience);
  const shouldShowLoginButton =
    !isAuthDataMissing &&
    ((!isLoading && !isAuthenticated) || error) &&
    auth.configuredOnRoutes;

  const handleThemeChange = (useDarkTheme: boolean) => {
    setTheme(useDarkTheme ? DARK_THEME : LIGHT_THEME);
  };
  return (
    <div className="flex items-center justify-end lg:pt-3 w-full">
      <div className="mr-4 flex items-center">
        <Toggle
          isEnabled={theme !== LIGHT_THEME}
          enabledBackgroundStyle="bg-[#3d4eac]"
          disabledBackgroundStyle="bg-gray-200"
          onChange={handleThemeChange}
          enabledIcon={<MoonIcon className="w-4 h-4 text-[#3d4eac]" />}
          disabledIcon={<SunIcon className="w-4 h-4 text-yellow-400" />}
        />
      </div>
      {shouldShowLoginButton && (
        <div className="pr-3 lg:pr-20">
          <LoginButton />
        </div>
      )}
      {!isLoading && isAuthenticated && (
        <div className="pr-3 lg:pr-20">
          <UserIcon />
        </div>
      )}
    </div>
  );
};

export default Header;
