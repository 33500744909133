import { DevPortalThemeConfig } from "@zuplo/internal";

export const DARK_THEME: DevPortalThemeConfig = {
  backgroundNav: undefined,
  backgroundNavItemSelected: "#2a2f45",
  backgroundPrimary: "#1a1f36",
  textColorH1: "#f7fafc",
  textColorH2: "#f7fafc",
  textColorH3: "#f7fafc",
  textColorNav: "#a3acb9",
  textColorNavHovered: "#f7fafc",
  textColorNavSelected: "#6c8eef",
  textColorPrimary: "#c1c9d2",
};
