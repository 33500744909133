// Misc
export const LAUNCH_DARKLY_CLIENT_IDE =
  process.env.NODE_ENV === "production"
    ? "61b9f9970e42590f6e801af7"
    : "61b9f9970e42590f6e801af6";

export const MANAGEMENT_API_URL =
  process.env.REACT_APP_MANAGEMENT_API_URL ?? "https://api.zuplo.com";

// Sentry stuff
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? undefined;
export const SENTRY_ENV = process.env.NODE_ENV;
export const SENTRY_RELEASE = process.env.CF_PAGES_COMMIT_SHA;
export const SENTRY_ORGANIZATION = "zuplo";
export const SENTRY_PROJECT = "dev-portal";
