import * as Sentry from "@sentry/react";
import HeaderWithNav from "components/layout/header/HeaderWithNav";
import LoadingIcon from "components/layout/Loading";
import Sidebar from "components/layout/sidebar/Sidebar";
import { UserGeneratedPageContext } from "context/UserGeneratedPageContext";
import { useContext, useEffect } from "react";
import useSWR from "swr";
import { getClientConfig } from "../config";

type UserGeneratedPageProps = {
  content: {
    id: string;
    url: string;
  };
};

const UserGeneratedPage = ({ content }: UserGeneratedPageProps) => {
  const { logoUrl } = getClientConfig();
  const userGeneratedPages = useContext(UserGeneratedPageContext);
  const { data: htmlContent, error } = useSWR(content.url, (url: string) =>
    fetch(url).then((res) => res.text())
  );

  useEffect(() => {
    // Scroll to focused section upon reload
    const hash = window.location.hash;
    document
      .getElementById(hash.slice(1))
      ?.scrollIntoView({ behavior: "auto", block: "start" });
  }, [htmlContent]);

  if (error) {
    Sentry.captureException(error);
    throw new Error(`Error loading page at '${content.url}`);
  }

  if (!htmlContent) {
    return <LoadingIcon className="h-5 w-full" />;
  }

  const fileContentDOM = new DOMParser().parseFromString(
    htmlContent,
    "text/html"
  );
  const parsingFailed = fileContentDOM.querySelector("parseerror") != null;
  let innerNav = null;
  if (!parsingFailed) {
    const h2Tags = Array.from(fileContentDOM.body.children).filter(
      (child) => child.tagName === "H2"
    );
    const h2IDs = h2Tags.map((h2Tag) => h2Tag.id);
    const tagNames = h2Tags.map((h2Tag) => h2Tag.textContent);

    innerNav =
      tagNames.length > 0 ? (
        <div className="flex text-nav-text flex-col text-sm gap-y-2 sticky top-20">
          <p className="font-medium">ON THIS PAGE</p>
          {tagNames.map((h2TagName, i) => (
            <a
              className="hover:text-nav-text-hovered"
              key={h2IDs[i]}
              href={`#${h2IDs[i]}`}
            >
              {h2TagName}
            </a>
          ))}
        </div>
      ) : null;
  }

  return (
    <div className="flex w-full h-screen w-screen bg-primary-background">
      <Sidebar pages={userGeneratedPages} iconURL={logoUrl} />
      <div className="w-full flex flex-col overflow-y-scroll">
        <HeaderWithNav pages={userGeneratedPages} activeID={content.id} />
        <div className="flex w-full justify-center my-10 lg:my-20">
          <div
            className="prose max-w-none w-5/6 lg:w-3/6"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
          {innerNav ? (
            <div className="mr-4 ml-4 hidden lg:inline">{innerNav}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserGeneratedPage;
