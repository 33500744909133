import { DevPortalClientConfig, DevPortalThemeConfig } from "@zuplo/internal";
import { DARK_THEME } from "./themes/dark";
import { LIGHT_THEME } from "./themes/light";
declare global {
  interface Window {
    config?: DevPortalClientConfig;
  }
}

interface DevPortalClientRuntimeConfig extends DevPortalClientConfig {
  sitePath: string;
  faviconUrl: string;
  logoUrl: string;
  pageTitle: string;
  lightTheme: DevPortalThemeConfig;
  darkTheme: DevPortalThemeConfig;
}

function loadClientConfig(): DevPortalClientRuntimeConfig {
  const config = window.config;

  if (!config) {
    throw new Error("Could not retrieve config");
  }

  // This is where default values for configuration go
  // NOTICE: If you set a default here, it needs to be added to the
  // above DevPortalClientRuntimeConfig interface. That interface overrides
  // nullable values. i.e. if there is a default value it never is null
  const defaultConfig: Partial<DevPortalClientConfig> = {
    sitePathname: "/",
    faviconUrl:
      process.env.NODE_ENV !== "production"
        ? `${process.env.PUBLIC_URL}/favicon-dev.ico`
        : `${process.env.PUBLIC_URL}/favicon.ico`,
    logoUrl: `${process.env.PUBLIC_URL}/made-with-zuplo.svg`,
    pageTitle: "Zuplo Dev Portal",
    lightTheme: LIGHT_THEME,
    darkTheme: DARK_THEME,
  };

  // This is where you set things that are always set locally
  const localOverrideConfig: Partial<DevPortalClientConfig> =
    process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test"
      ? {
          apiUrl: "http://localhost:4100/local",
          assetsUrl: "http://localhost:4100/cdn",
        }
      : {};

  // Merge all the configs together, order matters
  const mergedConfig: Partial<DevPortalClientConfig> = {
    ...defaultConfig,
    ...config,
    ...localOverrideConfig,
  };

  // eslint-disable-next-line no-console
  console.log(mergedConfig);
  return mergedConfig as DevPortalClientRuntimeConfig;
}

let clientConfig: DevPortalClientRuntimeConfig | undefined;
export function getClientConfig(): DevPortalClientRuntimeConfig {
  if (!clientConfig) {
    clientConfig = loadClientConfig();
  }
  return clientConfig;
}
