import { DevPortalThemeConfig } from "@zuplo/internal";
import React from "react";
import { LIGHT_THEME } from "../themes/light";

export const ThemeContext = React.createContext<
  [DevPortalThemeConfig, (theme: DevPortalThemeConfig) => void]
>([
  LIGHT_THEME,
  () => {
    return;
  },
]);
